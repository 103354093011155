import { type FunctionComponent } from 'react'
import { ContentCardV2, Grid, GridItem, Heading } from '@which/seatbelt'

import classNames from 'classnames'

import type { Recommendations } from '../../../../generated/frontend'
import styles from './RecommendedArticles.module.scss'

export const RecommendedArticles: FunctionComponent<RecommendedProps> = ({
  articles,
  whichId,
  modelId,
}) => {
  return (
    <Grid className={classNames('w-page-wrapper', styles.sectionSpacing)}>
      <GridItem
        columnStart={{ large: 2 }}
        span={{ large: 10 }}
        className={styles.mobileMaxWidthWrapper}
      >
        <Heading
          headingType="large"
          heading="Recommended for you"
          headingTag="h2"
          subHeading="Based on your interests"
        />

        <div className={styles.recommendedArticlesContainer}>
          {articles.map((article, index) => (
            <ContentCardV2
              title={article.title}
              primaryLink={article.href}
              key={index}
              description={article.subheading}
              imgObj={{
                imageAlt: article.title,
                str: article.image,
              }}
              trackingData={{
                'data-which-id': whichId,
                'data-section': 'Recommended for you',
                'data-card-name': article.title,
                'data-index': (index + 1).toString(),
                'data-content-id': article.id,
                'data-model-id': modelId,
              }}
            />
          ))}
        </div>
      </GridItem>
    </Grid>
  )
}

type RecommendedProps = {
  articles: Recommendations['articles']
  whichId: string
  modelId: string
}
