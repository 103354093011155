import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, Heading } from '@which/seatbelt'

import { magazineLinkList, section } from '../../constants/magazineLinks'
import styles from './MagazineLinks.module.scss'

export const MagazineLinks: FunctionComponent = () => (
  <section aria-labelledby="magazinelinks-header">
    <Heading
      headingType="large"
      heading={section}
      headingTag="h2"
      subHeading="Get answers from our experts"
    />

    <nav aria-label="Magazines links and services">
      <ul className={styles.list}>
        {magazineLinkList.map((magazineLink, ind) => (
          <li key={magazineLink.href}>
            <ArticleCard
              {...magazineLink}
              clampStrapline={false}
              className={styles.magazineLink}
              mainLinkProps={{
                'data-which-id': 'Homepage Card',
                'data-section': section,
                'data-card-name': magazineLink.title,
                'data-index': ind + 1,
              }}
            />
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
