import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import {
  ButtonLink,
  CardContainer,
  Heading,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser'
import parse, { domToReact, Element } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'

import type { ImageWithSources } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import { isAndroid, isIPhone } from '../../../../shared/utils/detect-device'
import styles from './MemberBenefitsLoggedIn.module.scss'

const handleTracking = (targetText: string, itemIndex: number | undefined, event: string): void => {
  if (itemIndex !== undefined) {
    itemIndex++
  }

  dynamicGa4DataLayerPush({
    event: event,
    item_text: targetText,
    item_index: itemIndex ? itemIndex : undefined,
    item_group: 'benefits panel',
  })
}

export const MemberBenefitsLoggedIn: FunctionComponent<Props> = ({
  title,
  calloutText,
  buttonLink,
  className,
  headingId,
  memberBenefitsLoggedIn,
  memberOffer,
  onwardJourney,
  ...rest
}) => {
  const parseOnwardJourney = () => {
    if (!onwardJourney) {
      return null
    }

    const sanitizedHTML = DOMPurify.sanitize(onwardJourney)
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element && domNode.name === 'a') {
          const { href } = domNode.attribs
          return (
            <Link href={href} data-testid="standard-link-primary">
              {domToReact(domNode.children as DOMNode[])}
            </Link>
          )
        }
      },
    }
    return (
      <Typography textStyle="sb-text-body-default-regular">
        {parse(sanitizedHTML, options)}
      </Typography>
    )
  }

  return (
    <article
      aria-labelledby={headingId}
      className={classnames(styles.panel, className)}
      {...rest}
      data-testid="mem-benefits-logged-in"
    >
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id={headingId}
          tag="h2"
          textStyle="sb-text-heading-large"
          className={styles.panelTitle}
        >
          {title}
        </Typography>
      </div>

      <div className={styles.benefits}>
        <div className={styles.benefitsWrapper}>
          {memberBenefitsLoggedIn.map((item, index, array) => {
            const isLastItem = index === array.length - 1

            if (
              memberOffer?.isActive &&
              (memberOffer?.offerText || memberOffer?.offerTitle) &&
              memberOffer?.href &&
              isLastItem
            ) {
              return (
                <MemberBenefitsExclusiveOfferRow
                  key={`member-benefit-${index}`}
                  memberOffer={memberOffer}
                  index={index}
                />
              )
            }

            return <MemberBenefitsRow key={`member-benefit-${index}`} {...item} index={index} />
          })}
        </div>
      </div>

      <div className={styles.panelAccountStrapline}>
        <Typography tag="p" textStyle="sb-text-body-default-regular">
          {calloutText}
        </Typography>
      </div>

      {onwardJourney ? (
        parseOnwardJourney()
      ) : (
        <ButtonLink
          href={buttonLink?.href}
          className={styles.panelAccountButton}
          data-which-id="benefits-panel-button"
          aria-label="myAccount"
          onClick={(e) => {
            const { textContent } = e.target
            handleTracking(textContent, undefined, 'click_button')
          }}
        >
          <ChevronRightIcon /> {buttonLink?.text}
        </ButtonLink>
      )}
    </article>
  )
}

const MemberBenefitsExclusiveOfferRow: FunctionComponent<MemberOfferProps> = ({
  memberOffer: { offerTitle, offerText, href, image },
}) => {
  if (!offerText && !offerTitle) {
    return null
  }

  return (
    <div className={styles.MemberBenefitsExclusiveOfferRow} data-testid="member-exclusive-offer">
      <Heading headingTag="h3" headingType="medium" heading="Member exclusive offer:" />

      <CardContainer
        ariaLabel={offerText}
        id={'member-exclusive-offer'}
        arrangement={'center'}
        primaryLink={href}
        onClickHandler={() => {
          handleTracking('Exclusive member offer', undefined, 'click_link')
        }}
      >
        {image && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <RenderPicture image={image} />
          </div>
        )}

        {offerTitle && <Heading headingTag="h4" headingType="small" heading={offerTitle} />}

        {offerText && (
          <Typography tag="p" textStyle="sb-text-body-small-regular">
            {offerText}
          </Typography>
        )}
      </CardContainer>
    </div>
  )
}

const MemberBenefitsRow: FunctionComponent<MemberBenefitsProps> = ({
  href,
  icon,
  title,
  index,
}) => {
  const [memberBenefitsAppLink, setMemberBenefitsAppLink] = useState<string>('')

  useEffect(() => {
    const { userAgent } = navigator
    const defaultAppLink: string = 'https://signup.which.co.uk/app-download'
    const iosAppLink: string =
      'https://apps.apple.com/app/apple-store/id1082710781?pt=813236&ct=homepage-member-benefits-panel&mt=8'
    const androidAppLink: string =
      'https://play.google.com/store/apps/details?id=uk.co.which.reviews&referrer=utm_source%3Dwhich-website%26utm_medium%3Dreferral%26utm_campaign%3Dhomepage-member-benefits-panel%26anid%3Dadmob'

    if (isIPhone(userAgent)) {
      setMemberBenefitsAppLink(iosAppLink)
    } else if (isAndroid(userAgent)) {
      setMemberBenefitsAppLink(androidAppLink)
    } else {
      setMemberBenefitsAppLink(defaultAppLink)
    }
  }, [])

  const createAppLink = () => {
    if (title === 'Get the Which? app') {
      return memberBenefitsAppLink
    }

    return href
  }

  return (
    <div className={styles.benefitsRow}>
      <RenderPicture image={icon} />

      <Link
        href={createAppLink()}
        className={styles.benefitsRowLink}
        data-which-id="benefits-panel-link"
        onClick={(e) => {
          const { textContent } = e.target
          handleTracking(textContent, index, 'click_link')
        }}
        data-testid="standard-link-primary"
      >
        <Typography
          tag="span"
          textStyle="sb-text-body-default-strong"
          className={styles.benefitsRowText}
        >
          {title}
        </Typography>
      </Link>
    </div>
  )
}

const RenderPicture: FunctionComponent<{ image: ImageWithSources }> = ({ image }) => {
  return (
    <Picture
      src={image.src}
      alt={image.alt}
      sources={image.sources}
      aspectRatioMain="one-to-one"
      height="62"
      width="62"
      className={styles.benefitsRowIcon}
      imageClassName={styles.benefitsRowIconImage}
      aria-hidden
    />
  )
}

///////// IMPLEMENTATION /////////

export type MemberBenefitLoggedIn = {
  icon: ImageWithSources
  href: string
  title: string
}

export type Props = {
  title: string
  overlineText: string
  headingId: string
  isLoggedIn?: boolean
  caption?: string
  calloutText?: string
  buttonLink?: {
    text: string
    href: string
  }
  memberOffer?: {
    href: string | undefined
    image: ImageWithSources | null
    isActive: boolean
    offerText: string | undefined
    offerTitle: string | undefined
  }
  memberBenefitsLoggedIn: MemberBenefitLoggedIn[]
  onwardJourney: string | undefined
} & ComponentPropsWithoutRef<'div'>

type MemberBenefitsProps = {
  icon: ImageWithSources
  href: string
  title: string
  index: number
}

type MemberOfferProps = {
  index: number
  memberOffer: {
    href: string | undefined
    image: ImageWithSources | null
    offerTitle: string | undefined
    offerText: string | undefined
  }
}
