import { useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedArticles } from '../RecommendedArticles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RecommendedForYouContainer = (props: {
  recommendations: Recommendations
  whichId: string
  modelId: string
}) => {
  // variation will be one of: "control", "reviews", "articles", "both"
  const variation = useFeatureValue('recommended-for-you', 'control')

  switch (variation) {
    // TODO  more cases for the different variations
    case 'articles':
      return (
        <RecommendedArticles
          articles={props.recommendations.articles}
          whichId={props.whichId}
          modelId={props.modelId}
        />
      )
    default:
      return null
  }
}
