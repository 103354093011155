import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
  Button,
  List,
  ListItem,
  Picture,
  SignupButton,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ArrowLeftIcon, ArrowRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { useFocusRing } from '@react-aria/focus'
import type { GridListProps as AriaGridListProps } from '@react-aria/gridlist'
import { useGridList, useGridListItem } from '@react-aria/gridlist'
import { mergeProps } from '@react-aria/utils'
import { Item } from '@react-stately/collections'
import type { ListProps, ListState } from '@react-stately/list'
import { useListState } from '@react-stately/list'
import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './MemberBenefits.module.scss'

const handleTracking = (targetText: string, itemIndex: number | undefined, event: string): void => {
  if (itemIndex !== undefined) {
    itemIndex++
  }

  dynamicGa4DataLayerPush({
    event: event,
    item_text: targetText,
    item_index: itemIndex,
    item_group: 'benefits panel',
  })
}

export const MemberBenefits: FunctionComponent<Props> = ({
  title,
  overlineText,
  className,
  headingId,
  caption,
  calloutText,
  buttonLink,
  memberBenefitsLoggedOut,
  ...rest
}) => {
  return (
    <article
      aria-labelledby={headingId}
      className={classnames(styles.panel, className)}
      {...rest}
      data-testid="mem-benefits"
    >
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id={headingId}
          tag="h2"
          textStyle="sb-text-heading-large"
          className={styles.panelTitle}
        >
          {title}
        </Typography>

        <Typography textStyle="sb-text-heading-overline" className={styles.panelOverline}>
          {overlineText}
        </Typography>
      </div>
      <GridList
        memberBenefits={memberBenefitsLoggedOut}
        aria-labelledby={headingId}
        calloutText={calloutText}
      >
        {memberBenefitsLoggedOut.map((item, index) => (
          <Item textValue={` ${item.title} - press to read more`} key={index}>
            <MemberBenefitsRow {...item} />
          </Item>
        ))}
      </GridList>
      <SignupButton
        caption={caption}
        buttonLink={buttonLink}
        data-which-id="benefits-panel-button"
        onClick={(e) => {
          const { textContent } = e.target
          handleTracking(textContent, undefined, 'click_button')
        }}
      />
    </article>
  )
}

///////// IMPLEMENTATION /////////

const GridList: FunctionComponent<GridListProps> = ({ memberBenefits, calloutText, ...props }) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const [selectedBenefit, setSelectedBenefit] = useState<number | null>(null)
  const stateProps = props as ListProps<HTMLUListElement>
  const state = useListState(stateProps)
  const listRef = useRef<HTMLUListElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const listItemRefs = useRef<HTMLLIElement | null[]>([])

  useEffect(() => {
    if (selectedBenefit !== null) {
      const item = listItemRefs.current[selectedBenefit] || 0
      const { textContent } = item
      handleTracking(textContent, selectedBenefit, 'click_link')
      setShowOverlay(true)
    } else {
      setShowOverlay(false)
    }
  }, [selectedBenefit])

  const onAction: AriaGridListProps<HTMLUListElement>['onAction'] = (key) => {
    setSelectedBenefit(typeof key === 'number' ? key : parseInt(key))
  }

  const onClose = () => {
    setShowOverlay(false)
  }

  const onTransitionEnd = () => {
    if (showOverlay) {
      overlayRef.current?.focus()
    } else {
      listItemRefs.current[selectedBenefit || 0]?.focus()
      setSelectedBenefit(null)
    }
  }

  const { gridProps } = useGridList({ onAction, selectionMode: 'none', ...props }, state, listRef)

  return (
    <div className={styles.gridListWrapper}>
      <ul {...gridProps} ref={listRef} className={styles.gridList} aria-hidden={showOverlay}>
        {Array.from(state.collection).map((item, index) => (
          <GridListItem
            focusable={!showOverlay}
            key={item.key}
            item={item}
            state={state}
            ref={(el) => (listItemRefs.current[index] = el)}
          />
        ))}
      </ul>
      <Overlay
        hidden={!showOverlay}
        ref={overlayRef}
        onClose={onClose}
        memberBenefit={memberBenefits[selectedBenefit || 0]}
        onTransitionEnd={onTransitionEnd}
      />
      {calloutText && <span className={styles.callout}>{calloutText}</span>}
    </div>
  )
}

const GridListItem = forwardRef<HTMLLIElement, GridListItemProps>(
  ({ item, state, focusable }, ref) => {
    const internalRef = useRef<HTMLLIElement>(null)
    useImperativeHandle<HTMLLIElement | null, HTMLLIElement | null>(ref, () => internalRef.current)

    const { rowProps, gridCellProps, isPressed } = useGridListItem(
      { node: item },
      state,
      internalRef
    )

    const { isFocusVisible, focusProps } = useFocusRing()

    return (
      <li
        ref={internalRef}
        data-which-id="benefits-panel-link"
        {...mergeProps(rowProps, focusProps)}
        className={classnames(styles.listItem, isPressed && styles.listItemPressed)}
        {...(!focusable && { tabIndex: -1 })}
      >
        <div
          className={classnames(isFocusVisible && styles.listItemFocusVisible)}
          {...gridCellProps}
        >
          {item.rendered}
        </div>
      </li>
    )
  }
)

const MemberBenefitsRow: FunctionComponent<MemberBenefitsButtonProps> = ({ title, icon }) => (
  <div className={styles.benefitsRow}>
    <Picture
      src={icon.src}
      alt={icon.alt}
      sources={icon.sources}
      aspectRatioMain="one-to-one"
      height="62"
      width="62"
      className={styles.icon}
      imageClassName={styles.iconImage}
      aria-hidden
    />
    <div className={styles.benefitsRowTextWrapper}>
      <Typography
        tag="span"
        textStyle="sb-text-body-default-strong"
        className={styles.benefitsRowText}
      >
        {title}
      </Typography>
    </div>
    <ArrowRightIcon />
  </div>
)

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  ({ onClose, memberBenefit: { icon, title, overlay }, className, hidden, ...rest }, ref) => {
    const { isFocusVisible, focusProps } = useFocusRing()

    return (
      <section
        tabIndex={-1}
        className={classnames(
          styles.overlay,
          isFocusVisible && styles.overlayFocusVisible,
          hidden && styles.overlayHidden,
          className
        )}
        ref={ref}
        aria-label={`${title} member benefit info`}
        aria-hidden={hidden}
        {...focusProps}
        {...rest}
      >
        <div className={styles.overlayHeader}>
          <Button
            onClick={onClose}
            appearance="secondary"
            className={styles.overlayButton}
            aria-label="Back to list"
            {...(hidden && { tabIndex: -1 })}
          >
            <ArrowLeftIcon
              alt="Back to member benefits list"
              className={styles.overlayButtonIcon}
            />
          </Button>
          <Picture
            src={icon.src}
            alt={icon.alt}
            sources={icon.sources}
            aspectRatioMain="one-to-one"
            height="62"
            width="62"
            className={styles.icon}
            imageClassName={styles.iconImage}
            aria-hidden
          />
          <Typography
            tag="span"
            textStyle="sb-text-body-default-strong"
            className={styles.benefitsRowText}
          >
            {title}
          </Typography>
        </div>
        <List className={styles.overlayList}>
          {overlay.map(({ title: listItemTitle, listItemText }, index) => (
            <ListItem icon="tick" key={index} className={styles.overlayListItem}>
              {title && (
                <Typography textStyle="sb-text-body-default-strong" tag="h3">
                  {listItemTitle}
                </Typography>
              )}
              {listItemText && (
                <Typography textStyle="sb-text-body-default-regular" tag="p">
                  {listItemText}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </section>
    )
  }
)

export type MemberBenefit = {
  text: string
  image: ImageWithSources
  list: {
    title: string | null
    text: string | null
  }[]
}

export type MemberBenefitsLoggedOut = {
  icon: ImageWithSources
  title: string
  overlay: {
    title: string
    listItemText: string
  }[]
}

export type Props = {
  title: string
  overlineText: string
  headingId: string
  caption?: string
  calloutText?: string
  isLoggedIn?: boolean
  buttonLink?: {
    text: string
    href: string
  }
  memberBenefitsLoggedOut: MemberBenefitsLoggedOut[]
  details?: MemberBenefit[]
} & ComponentPropsWithoutRef<'div'>

type GridListProps = {
  memberBenefits: MemberBenefitsLoggedOut[]
  calloutText?: string
} & AriaGridListProps<HTMLUListElement>

type GridListItemProps = {
  item: any
  state: ListState<HTMLUListElement>
  focusable: boolean
}

type MemberBenefitsButtonProps = {
  title: string
  icon: ImageWithSources
}

type OverlayProps = {
  onClose: Parameters<typeof Button>[0]['onClick']
  memberBenefit: MemberBenefitsLoggedOut
  hidden: boolean
} & ComponentPropsWithoutRef<'div'>
