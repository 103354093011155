const internalRef = '?internalReferral=homepage_card'

const popularLinks = [
  {
    href: 'https://www.which.co.uk/technology',
    text: 'Tech',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-604c4627-9ffb-48a9-b279-6f907b0b3e87-tech-icon-1.svg',
  },
  {
    href: 'https://www.which.co.uk/appliances',
    text: 'Appliances',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-183a0a6a-62a7-4f5c-99e0-123d3c3876f1-appliances-icon-1.svg',
  },
  {
    href: 'https://www.which.co.uk/home-and-garden',
    text: 'Home & garden',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-d60e6476-a93c-405e-bc38-c97329222071-home-and-garden-icon-1.svg',
  },
  {
    href: 'https://www.which.co.uk/money',
    text: 'Money',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-cd7a2c76-30c2-443c-b528-2cd4ed99f181-money-icon-1.svg',
  },
  {
    href: 'https://www.which.co.uk/cars',
    text: 'Cars',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-21a54da9-edf2-4c92-be16-49205c3ee20f-cars-icon-1.svg',
  },
  {
    href: 'https://www.which.co.uk/travel',
    text: 'Travel',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-a94faf07-b0d0-4fcb-a9f5-095202d2fc7b-travel-icon-1.svg',
  },
]

const lifeAdminLinks = [
  {
    href: `https://whichwills.which.co.uk/wills${internalRef}`,
    text: 'Write a will',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-acfc9b72-cda5-4aaa-8025-bb3a4533dd8f-write-a-will.svg',
  },
  {
    href: `https://trustedtraders.which.co.uk${internalRef}`,
    text: 'Home improvements',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-5b232d2e-6702-4bb2-a200-6020eadb38b1-find-a-trusted-trader.svg',
  },
  {
    href: `https://legalservice.which.co.uk${internalRef}`,
    text: 'Legal advice',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-28f02802-7c9d-4125-ad10-f113519b3bba-get-legal-help.svg',
  },
  {
    href: `https://broadband.which.co.uk${internalRef}`,
    text: 'Compare broadband',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-7b805146-0b38-49cc-ab12-07f2a309026a-compare-broadband-packages.svg',
  },
  {
    href: `https://whichwills.which.co.uk/power-of-attorney${internalRef}`,
    text: 'Power of attorney',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-28f02802-7c9d-4125-ad10-f113519b3bba-get-legal-help.svg',
  },
  {
    href: `https://www.which.co.uk/compare-providers${internalRef}`,
    text: 'More services',
    icon: 'https://media.product.which.co.uk/prod/files/file/gm-8807e4de-371e-480a-9cd8-e43f4bedb196-more-services.svg',
  },
]

export const popularLinksData = {
  id: 'popular-on-which-header',
  title: 'Popular links',
  subheading: 'Smart advice on products and brands',
  links: popularLinks,
}

export const lifeAdminLinksData = {
  id: 'life-admin-links',
  title: 'Get your life admin sorted',
  subheading: 'Use our services and comparison tools to get a head start',
  links: lifeAdminLinks,
}
