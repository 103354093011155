import { getImageSources } from '../../../shared/utils/getImageSources'

const sizes = '(min-width: 1440px) 500px, (min-width: 768px) 50vw, 90vw'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const section = 'Campaigns'

export const campaignLinkList: ContentCardProps = [
  {
    primaryLink: 'https://www.which.co.uk/campaigns/end-the-insurance-rip-off',
    title: 'End the Insurance Rip-Off',
    description:
      "Insurance companies are getting away with ripping us off when we need them most. It's time for the regulator to get tough with badly behaving companies. Demand action by signing the petition.",
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'End the Insurance Rip-Off, Which? Petition',
      str: 'https://media.product.which.co.uk/prod/images/original/da21454d5f0b-etir-email-banner-no-text-600-x-300.jpg',
      imageSources: [
        {
          srcset: getImageSources(
            'da21454d5f0b-etir-email-banner-no-text-600-x-300',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Sign the petition',
  },
  {
    primaryLink: 'https://www.which.co.uk/campaigns/customer-service-counts',
    title: 'Customer Service Counts',
    description:
      "What happened to customer service? We're passed around from pillar to post, on hold for ages in frustrating phone queues. Companies say we're important to them - it's time to show us they mean it and put customers first.",
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'Customer service counts, Which? Campaign',
      str: 'https://media.product.which.co.uk/prod/images/original/2b4201a365a0-csc-campaign-thumbnails.jpg',
      imageSources: [
        {
          srcset: getImageSources('2b4201a365a0-csc-campaign-thumbnails', ratio, renditions),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Find out more',
  },
  {
    primaryLink: 'https://www.which.co.uk/campaigns/stampout-scams',
    title: 'Stamp Out Scams',
    description:
      'Scammers steal hundreds of millions from innocent victims every year, bombarding us with fraudulent texts, emails and calls. The Government and businesses need to do more to protect us from scams. Demand change by signing the petition.',
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'Stamp out scams, Which? Petition',
      str: 'https://media.product.which.co.uk/prod/images/original/c927bc10be30-scam-campaign-thumbnails.png',
      imageSources: [
        {
          srcset: getImageSources('c927bc10be30-scam-campaign-thumbnails', ratio, renditions),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Sign the petition',
  },
]

type ContentCardProps = {
  primaryLink: string
  title: string
  description: string
  imgObj: {
    aspectRatioMain:
      | 'one-to-one'
      | 'two-to-one'
      | 'three-to-one'
      | 'three-to-five'
      | 'four-to-three'
      | undefined
    imageAlt: string
    str: string
    imageSources: {
      srcset: string
      sizes: string
    }[]
  }
  buttonLinkText: string
}[]
